import { Offcanvas, Form, Row, Col, Button } from "react-bootstrap";
import styles from "../styles/common/form.module.css";
import Multiselect from "multiselect-react-dropdown";
import { MoonPhases } from "../models/moonPhases.js";
import { useState, useEffect, useRef } from "react";
import { ApiClient } from "../helpers/apiClient.js";
import { ApiClientType } from "../models/apiClientType.js";
import dateFormat from "dateformat";

export default function SpeciesForm(props) {
  const [landingCenterList, setLandingCenterList] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [skuListLoading, setSkuListLoading] = useState(false);
  const [skuListSearch, setSkuListSearch] = useState("");
  const [skuListSelect, setSkuListSelect] = useState(false);
  const [speciesName, setSpeciesName] = useState("");
  const [catchQuantity, setCatchQuantity] = useState(0);
  const [catchQuantityUnit, setCatchQuantityUnit] = useState("Tons");
  const [fishingEffort, setFishingEffort] = useState("");
  const [moonPhase, setMoonPhase] = useState("");
  const [oceanTide, setOceanTide] = useState("");
  const [gearType, setGearType] = useState([]);

  useEffect(() => {
    if (Object.keys(props.value.editSpeciesData).length != 0) {
      setCatchQuantity(props.value.editSpeciesData.catch_quantity);
      setCatchQuantityUnit(props.value.editSpeciesData.catch_quantity_unit);
      setFishingEffort(props.value.editSpeciesData.fishing_effort);
      setMoonPhase(props.value.editSpeciesData.moon_face);
      setOceanTide(props.value.editSpeciesData.ocean_tide);
      setLandingCenterList(props.value.editSpeciesData.landing_center);
      setSpeciesName(props.value.editSpeciesData.species_name);
      if (props.value.editSpeciesData.gear_type) {
        setGearType(props.value.editSpeciesData.gear_type);
      }
      setSkuListSelect(true);
    }
  }, []);
  // const limit = 30;
  // const [offset, setOffset] = useState(0);
  // useEffect(() => {
  //   let a = (e) => {
  //     if (
  //       e.target.scrollHeight - e.target.scrollTop ===
  //       e.target.clientHeight
  //     ) {
  //       console.log(e.target.scrollHeight / 440);
  //       setOffset(parseInt(e.target.scrollHeight / 440) * 10);
  //     }
  //   };
  //   document
  //     .getElementsByClassName("optionContainer")[1]
  //     .addEventListener("scroll", a);
  //   return () => {
  //     document
  //       .getElementsByClassName("optionContainer")[1]
  //       .removeEventListener("scroll", a);
  //   };
  // }, []);
  useEffect(() => {
    let getData;
    (async () => {
      setSkuListLoading(true);
      getData = setTimeout(async () => {
        let a = await ApiClient(
          ApiClientType.post,
          process.env.REACT_APP_CMS_BASE_URL,
          "",
            {
              query: `query SearchProducts($searchQuery: SearchQuery) {\n  searchProducts(searchQuery: $searchQuery) {\n    displayName: name\n    name\n\n    code\n  }\n}`,
              variables: {
                searchQuery: {
                  filters: [
                    {
                      field: `name`,
                      operator: `ILIKE`,
                      value: `%${skuListSearch}%`
                    }
                  ],
                  page: {
                    fetchSize: 999,
                    pageNumber: 1
                  }
                }
              },
              operationName: `SearchProducts`
            }
        );

        setSkuListLoading(false);
        // setSkuList([...skuList, ...a.body]);
        // console.log(a.data.searchProducts);
        setSkuList(a.data.searchProducts);
      }, 2000);
    })();
    return () => clearTimeout(getData);
  }, [skuListSearch]);

  let options = [];
  props.value.landingCenterCoordinates.features.map((e) => {
    let obj = {
      landing_center_name: `${e.properties.LANDINGNAM}-${e.properties.STATENAME}`,
      landing_center_id: `${e.id}`,
    };
    options.push(obj);
  });
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let result;
    if (catchQuantity == NaN) {
      setCatchQuantity(0);
    }
    if (Object.keys(props.value.editSpeciesData).length != 0) {
      result = await ApiClient(
        ApiClientType.post,
        process.env.REACT_APP_LANDING_BASE_URL,
        "/update",
        {
          mapping_id: props.value.mappingId,
          specie_id: props.value.editSpeciesData.id,
          specie: {
            landing_center: landingCenterList,
            species_name: speciesName,
            catch_quantity: catchQuantity,
            catch_quantity_unit: catchQuantityUnit,
            fishing_effort: fishingEffort,
            moon_face: moonPhase,
            ocean_tide: oceanTide,
            gear_type: gearType,
          },
        }
      );
    } else {
      if (props.value.mappingId != "") {
        result = await ApiClient(
          ApiClientType.post,
          process.env.REACT_APP_LANDING_BASE_URL,
          "/add",
          {
            mapping_id: props.value.mappingId,
            specie: {
              landing_center: landingCenterList,
              species_name: speciesName,
              catch_quantity: catchQuantity,
              catch_quantity_unit: catchQuantityUnit,
              fishing_effort: fishingEffort,
              moon_face: moonPhase,
              ocean_tide: oceanTide,
              gear_type: gearType,
            },
          }
        );
      } else {
        result = await ApiClient(
          ApiClientType.post,
          process.env.REACT_APP_LANDING_BASE_URL,
          "",
          {
            geometry: props.value.geoJsonGeometry,
            date_time: dateFormat(
              props.value.mapDate,
              "yyyy-mm-dd'T'HH:MM:ss'Z'"
            ),
            species: [
              {
                landing_center: landingCenterList,
                species_name: speciesName,
                catch_quantity: catchQuantity,
                catch_quantity_unit: catchQuantityUnit,
                fishing_effort: fishingEffort,
                moon_face: moonPhase,
                ocean_tide: oceanTide,
                gear_type: gearType,
              },
            ],
          }
        );
      }
    }
    if (result) {
      let c = await ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_LANDING_BASE_URL,
        "",
        {
          date_time: props.value.mapDate,
        }
      );
      props.value.setSpeciesData(c);
      props.value.setIsSideNavVisible(false);
    }
  };
  return (
    <div>
      <p id={styles.formLabel}>Species</p>
      <hr />
      <Form onSubmit={(e) => onSubmitHandler(e)}>
        <Row className="mb-3">
          <Form.Label id={styles.formLabel}>Landing center</Form.Label>
          <Form.Group as={Col}>
            <Multiselect
              selectedValues={
                landingCenterList != [] ? landingCenterList : null
              }
              options={options}
              closeIcon="cancel"
              placeholder="Select"
              id={styles.multiSelect}
              showArrow={true}
              closeOnSelect={false}
              displayValue="landing_center_name"
              onRemove={(list, item) => {
                setLandingCenterList(list);
              }}
              onSelect={(list, item) => {
                setLandingCenterList(list);
              }}
            />
          </Form.Group>
          <Form.Group as={Col}></Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label id={styles.formLabel}>Species</Form.Label>
            <Multiselect
              selectedValues={
                speciesName != "" ? [{ displayName: speciesName }] : null
              }
              singleSelect={skuListSelect}
              options={skuList}
              closeIcon="cancel"
              placeholder="Select Species"
              id={styles.multiSelect}
              showArrow={true}
              loading={skuListLoading}
              closeOnSelect={false}
              displayValue="displayName"
              onRemove={() => {
                setSkuListSelect(false);
                setSpeciesName("");
              }}
              onSearch={(e) => {
                setSkuListSearch(e);
              }}
              onSelect={(list, item) => {
                setSkuListSelect(true);
                setSpeciesName(item.name);
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Label id={styles.formLabel}>Quantity</Form.Label>
          <Form.Group as={Col}>
            <Form.Control
              placeholder="Quantity"
              type="number"
              min="0"
              step="0.1"
              value={catchQuantity}
              oninput="validate(this)"
              onChange={(e) => {
                var t = e.target.value;
                e.target.value =
                  t.indexOf(".") >= 0
                    ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
                    : t;
                setCatchQuantity(parseFloat(e.target.value));
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Select
              onChange={(e) => setCatchQuantityUnit(e.target.value)}
              value={catchQuantityUnit}
            >
              <option value="Tons">Tons</option>
              <option value="Kgs">Kgs</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-4">
          <Form.Label id={styles.formLabel}>Fishing Effort</Form.Label>
          <Form.Group as={Col}>
            {["Day", "Night", "Day/Night"].map((type) => (
              <div
                key={type}
                style={{ display: "inline-block", marginRight: "70px" }}
              >
                <Form.Check
                  key={type}
                  type="radio"
                  name="fishingEffort"
                  label={`${type}`}
                  value={type}
                  onChange={(e) => setFishingEffort(e.target.value)}
                  checked={type == fishingEffort ? true : false}
                />
              </div>
            ))}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label id={styles.formLabel}>Fishing Effort</Form.Label>
            <Multiselect
              selectedValues={gearType?.map((item) => {
                return { name: item };
              })}
              options={[
                { name: "Ring Net", id: 0 },
                { name: "Hook Net", id: 1 },
                { name: "Gill Net", id: 2 },
                { name: "Bottom Trawling", id: 3 },
                { name: "Purse Siene", id: 4 },
                { name: "Dolnet", id: 5 },
                { name: "Hand Line", id: 6 },
                { name: "Hook and Line", id: 7 },
                { name: "Long Liner", id: 8 },
                { name: "Pole and line", id: 9 },
                { name: "Ring Seine", id: 10 },
                { name: "Shore Seinet", id: 11 },
                { name: "Squid jigging vessel", id: 12 },
                { name: "Trawler", id: 13 },
              ]}
              closeIcon="cancel"
              placeholder="Gear Type"
              id={styles.multiSelect}
              showArrow={true}
              closeOnSelect={false}
              displayValue="name"
              onRemove={(list, item) => {
                let arr = gearType;
                const index = arr.indexOf(item.name);
                if (index > -1) {
                  arr.splice(index, 1);
                }
                setGearType(arr);
              }}
              onSelect={(list, item) => {
                setGearType([...gearType, item.name]);
              }}
            />
          </Form.Group>
        </Row>
        <br />
        <Row className="mb-3">
          <Form.Label id={styles.formLabel}>Moon Phase</Form.Label>
          <Form.Group as={Col}>
            {MoonPhases.map((type) => (
              <div
                key={type.id}
                style={{ display: "inline-block", marginRight: "11.5px" }}
              >
                <Form.Check.Label>
                  <Form.Check.Input
                    value={type.id}
                    type="radio"
                    name="moonPhase"
                    style={{ display: "none" }}
                    onChange={(e) => setMoonPhase(e.target.value)}
                  />
                  <div as={Col} style={{ textAlign: "center" }}>
                    {type.image(type.id == moonPhase ? true : false)}
                    <p
                      style={{
                        color: "grey",
                        fontSize: "15px",
                        marginTop: "5px",
                      }}
                    >
                      {type.label}
                    </p>
                  </div>
                </Form.Check.Label>
              </div>
            ))}
          </Form.Group>
        </Row>
        <Row className="mb-4">
          <Form.Label id={styles.formLabel}>Ocean tide</Form.Label>
          <Form.Group as={Col}>
            {["High", "Low", "Normal"].map((type) => (
              <div
                key={type}
                style={{ display: "inline-block", marginRight: "70px" }}
              >
                <Form.Check
                  key={type}
                  type="radio"
                  name="oceanTide"
                  label={`${type}`}
                  value={type}
                  onChange={(e) => setOceanTide(e.target.value)}
                  checked={type == oceanTide ? true : false}
                />
              </div>
            ))}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <div id={styles.buttonsContainer}>
              <Button
                type="submit"
                variant="primary"
                id={styles.saveButton}
              >
                Save Changes
              </Button>
            </div>
          </Form.Group>
        </Row>
      </Form>
    </div>
  );
}
