import Modal from "react-bootstrap/Modal";

export default function ImageModal(props) {
  return (
    <>
      <Modal
        show={props.value.showModal}
        fullscreen={true}
        onHide={() => props.value.setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={props.value.selectedImage}
            height="auto"
            width="100%"
            style={{
              marginBottom: "10px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
