import {
  MapContainer,
  LayersControl,
  TileLayer,
  useMap,
  Marker,
  Popup,
  Polygon,
  FeatureGroup,
  Rectangle,
  Tooltip,
} from "react-leaflet";
import { ApiClient } from "../helpers/apiClient.js";
import { useState, useEffect, useRef } from "react";
import { ApiClientType } from "../models/apiClientType.js";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { GeoJsonConverter } from "../helpers/geoJsonConverter.js";
import { GeoJsonType } from "../models/geoJsonType.js";
import LandingCenterPopup from "./landingCenterPopup.js";
import SpeciesPopup from "./speciesPopup.js";
import L from "leaflet";
import { DrawType } from "../models/drawType.js";
import { CoordinateConverter } from "../helpers/coordinateConverter.js";
import MouseCoordinates from "./mouseCoordinates.js";
import { colors } from "../assets/colors.js";
import { CompareSpecies } from "../helpers/compareSpecies.js";
import dateFormat from "dateformat";
import ChangeView from "../helpers/changeView.js";
import MapEvents from "../helpers/getMapEvents.js";
import CustomToast from "./toast.js";

export default function Map(props) {
  const [showToast, setShowToast] = useState(false);
  const [toastBody, setToastBody] = useState("");
  const [speciesCoordinates, setSpeciesCoordinates] = useState();
  const [centerCoords, setCenterCoords] = useState({
    lat: 12.9716,
    lng: 77.5946,
  });
  const [zoomLevel, setZoomLevel] = useState(5);
  const { BaseLayer } = LayersControl;
  const LeafIcon = L.Icon.extend({
    options: {},
  });
  const selectedLocationIcon = new LeafIcon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/149/149059.png",
    iconSize: [38, 35],
  });
  const landingCenterIcon = new LeafIcon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/565/565665.png",
    iconSize: [9, 10],
  });
  const markedSpeciesIcon = new LeafIcon({
    iconUrl:
      "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|2ecc71&chf=a,s,ee00FFFF",
  });

  useEffect(() => {
    setCenterCoords({
      lat: localStorage.getItem("lat"),
      lng: localStorage.getItem("lng"),
    });
    setZoomLevel(localStorage.getItem("zoom"));
    if (props.value.isWindy) {
      localStorage.setItem("isWindyDone", true);
    } else {
      setZoomLevel(5);
      localStorage.setItem("zoom", 5);
    }
  }, [props.value.isWindy]);

  useEffect(() => {
    (async () => {
      let b = await ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_LANDING_CENTER_COORDINATES,
        ""
      );
      props.value.setLandingCenterCoordinates(b);
      setCenterCoords({
        lat: props.value.searchLandingCenter.landing_center_coords[1],
        lng: props.value.searchLandingCenter.landing_center_coords[0],
      });
      setZoomLevel(10);
      localStorage.setItem(
        "lat",
        props.value.searchLandingCenter.landing_center_coords[1]
      );
      localStorage.setItem(
        "lng",
        props.value.searchLandingCenter.landing_center_coords[0]
      );
      localStorage.setItem("zoom", 10);
    })();
  }, [props.value.searchLandingCenter]);

  useEffect(() => {
    (async () => {
      let d = new Date(props.value.mapDate);
      let success = await getAvailablePfzDates(d);
      if (success) {
        let a = await ApiClient(
          ApiClientType.get,
          process.env.REACT_APP_SPEICES_COORDINATES,
          `/${dateFormat(d, "yyyymmdd")}/PFZ_TEXT_${dateFormat(
            d,
            "yyyymmdd"
          )}.json`
        );
        setSpeciesCoordinates(a);
      } else {
        setSpeciesCoordinates();
        setToastBody("No data available");
        setShowToast(true);
        // let d = new Date(props.value.mapDate);
        // d.setDate(d.getDate() - 2);
        // let success = await getAvailablePfzDates(d);
        // if (success) {
        //   let a = await ApiClient(
        //     ApiClientType.get,
        //     process.env.REACT_APP_SPEICES_COORDINATES,
        //     `/${dateFormat(d, "yyyymmdd")}/PFZ_TEXT_${dateFormat(
        //       d,
        //       "yyyymmdd"
        //     )}.json`
        //   );
        //   setSpeciesCoordinates(a);
        //   setToastBody(
        //     "No data available for current date, showing data for previous date"
        //   );
        //   setShowToast(true);
        // } else {
        //   setSpeciesCoordinates();
        //   setToastBody("No data available");
        //   setShowToast(true);
        // }
      }
      let c = await ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_LANDING_BASE_URL,
        "",
        {
          date_time: props.value.mapDate,
        }
      );
      props.value.setSpeciesData(c);
      setCenterCoords({ lat: 12.9716, lng: 77.5946 });
      setZoomLevel(5);
      localStorage.setItem("lat", 12.9716);
      localStorage.setItem("lng", 77.5946);
      localStorage.setItem("zoom", 5);
    })();
  }, [props.value.mapDate]);

  const getAvailablePfzDates = async (date) => {
    let a = await ApiClient(
      ApiClientType.get,
      process.env.REACT_APP_SPEICES_COORDINATES,
      `/${dateFormat(date, "yyyymmdd")}/PFZ_TEXT_${dateFormat(
        date,
        "yyyymmdd"
      )}.json`
    );
    if (a == undefined) return false;
    return true;
  };

  const onDeleteSpecie = async (specie, mappingId) => {
    let result = await ApiClient(
      ApiClientType.post,
      process.env.REACT_APP_LANDING_BASE_URL,
      "/delete",
      {
        mapping_id: mappingId,
        specie_id: specie.id,
      }
    );
    if (result == 200) {
      let c = await ApiClient(
        ApiClientType.get,
        process.env.REACT_APP_LANDING_BASE_URL,
        "",
        {
          date_time: props.value.mapDate,
        }
      );
      props.value.setSpeciesData(c);
    }
  };

  const onEditGeometry = async (mappingId, geometry) => {
    let result = await ApiClient(
      ApiClientType.patch,
      process.env.REACT_APP_LANDING_BASE_URL,
      "",
      {
        geometry: geometry,
        mapping_id: mappingId,
      }
    );
    let c = await ApiClient(
      ApiClientType.get,
      process.env.REACT_APP_LANDING_BASE_URL,
      "",
      {
        date_time: props.value.mapDate,
      }
    );
    props.value.setSpeciesData(c);
  };

  const onDeleteGeometry = async (mappingId) => {
    let result = await ApiClient(
      ApiClientType.delete,
      process.env.REACT_APP_LANDING_BASE_URL,
      `/${mappingId}`,
      {}
    );
    let c = await ApiClient(
      ApiClientType.get,
      process.env.REACT_APP_LANDING_BASE_URL,
      "",
      {
        date_time: props.value.mapDate,
      }
    );
    props.value.setSpeciesData(c);
  };

  const getLandingCenterData = async (systemId) => {
    let result = await ApiClient(
      ApiClientType.get,
      process.env.REACT_APP_LANDING_CENTER_URL,
      `/${systemId}`,
      {}
    );
    props.value.setSelectedLandingCenter(result);
  };
  return (
    <div id="map" style={{ marginTop: "3px" }}>
      <MapContainer
        center={[12.9716, 77.5946]}
        zoom={5}
        zoomSnap={1}
        minZoom={3}
      >
        <ChangeView
          setCenterCoords={setCenterCoords}
          setZoomLevel={setZoomLevel}
          isWindy={props.value.isWindy}
          center={[centerCoords.lat, centerCoords.lng]}
          zoom={zoomLevel}
          landingCenter={props.value.searchLandingCenter}
        />
        <MapEvents
          setCenterCoords={setCenterCoords}
          setZoomLevel={setZoomLevel}
          isWindy={props.value.isWindy}
          center={[centerCoords.lat, centerCoords.lng]}
          zoom={zoomLevel}
        />
        {props.value.isWindy ? (
          <div>
            <iframe
              src={`https://main.dmwedj0ij9xyu.amplifyapp.com/?lat=${localStorage.getItem(
                "lat"
              )}&long=${localStorage.getItem(
                "lng"
              )}&zoom=${localStorage.getItem("zoom")}`}
              style={{
                margin: "55px 0 0 0",
                position: "fixed",
                padding: "0px",
                height: "93%",
                width: "100%",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                border: "none",
              }}
            />
          </div>
        ) : (
          <div>
            <LayersControl>
              <BaseLayer checked name="Street View">
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              </BaseLayer>
              <BaseLayer name="Satellite View">
                <TileLayer url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY2FwdGFpbmZyZXNoaW4iLCJhIjoiY2xjNXI0cGQ3MHQ3azNvbWg4eWprdWc2MyJ9.wMWDqLuaXJ2aUc8drxbv2w" />
              </BaseLayer>
            </LayersControl>

            <FeatureGroup key={Math.random()}>
              {props.value.speciesData?.length == 0 ? (
                <EditControl
                  onCreated={(e) => {
                    props.value.setGeoJsonGeometry(
                      JSON.stringify(GeoJsonConverter(e.layer, e.layerType))
                    );
                    props.value.setIsSpecies(true);
                    props.value.setIsSideNavVisible(true);
                  }}
                  edit={{
                    edit: false,
                  }}
                  position="topright"
                  draw={{
                    rectangle: false,
                    circle: false,
                    circlemarker: false,
                    polyline: false,
                  }}
                />
              ) : null}
              {props.value.speciesData?.map((item, index) => {
                let geo;
                try {
                  geo = JSON.parse(item.geometry);
                } catch (e) {
                  geo = null;
                }
                return (
                  <div key={item.id}>
                    {index === 0 ? (
                      <EditControl
                        onCreated={(e) => {
                          props.value.setGeoJsonGeometry(
                            JSON.stringify(
                              GeoJsonConverter(e.layer, e.layerType)
                            )
                          );
                          props.value.setIsSpecies(true);
                          props.value.setIsSideNavVisible(true);
                        }}
                        onEdited={async (e) => {
                          e.layers.eachLayer((layer) => {
                            if (layer instanceof L.Polygon) {
                              onEditGeometry(
                                layer.options.pathOptions.id,
                                JSON.stringify(
                                  GeoJsonConverter(layer, DrawType.polygon)
                                )
                              );
                            } else if (layer instanceof L.Marker) {
                              onEditGeometry(
                                layer.options.pathOptions.id,
                                JSON.stringify(
                                  GeoJsonConverter(layer, DrawType.marker)
                                )
                              );
                            }
                          });
                        }}
                        onDeleted={(e) => {
                          e.layers.eachLayer((layer) => {
                            onDeleteGeometry(layer.options.pathOptions.id);
                          });
                        }}
                        position="topright"
                        draw={{
                          rectangle: false,
                          circle: false,
                          circlemarker: false,
                          polyline: false,
                        }}
                      />
                    ) : null}
                    {geo != null ? (
                      <div>
                        {geo.geometry.type == GeoJsonType.polygon ? (
                          <Polygon
                            pathOptions={{
                              color: item.color ? item.color : "black",
                              id: item.id,
                            }}
                            positions={CoordinateConverter(
                              geo.geometry.coordinates[0]
                            )}
                          >
                            <Popup closeButton={false}>
                              <SpeciesPopup
                                value={{
                                  date: props.value.mapDate,
                                  item: item,
                                  onAddSpecies: () => {
                                    props.value.setGeoJsonGeometry(geo);
                                    props.value.setMappingId(item.id);
                                    props.value.setIsSpecies(true);
                                    props.value.setIsSideNavVisible(true);
                                  },
                                  onEditSpecies: (specie) => {
                                    props.value.setEditSpeciesData(specie);
                                    props.value.setMappingId(item.id);
                                    props.value.setIsSpecies(true);
                                    props.value.setIsSideNavVisible(true);
                                  },
                                  onDeleteSpecie: (specie) => {
                                    onDeleteSpecie(specie, item.id);
                                  },
                                  onColorChange: async (color) => {
                                    let result = await ApiClient(
                                      ApiClientType.patch,
                                      process.env.REACT_APP_LANDING_BASE_URL,
                                      "",
                                      {
                                        color: color,
                                        geometry: item.geometry,
                                        mapping_id: item.id,
                                      }
                                    );
                                    let c = await ApiClient(
                                      ApiClientType.get,
                                      process.env.REACT_APP_LANDING_BASE_URL,
                                      "",
                                      {
                                        date_time: props.value.mapDate,
                                      }
                                    );
                                    props.value.setSpeciesData(c);
                                  },
                                }}
                              />
                            </Popup>
                          </Polygon>
                        ) : (
                          <Marker
                            pathOptions={{
                              id: item.id,
                            }}
                            icon={markedSpeciesIcon}
                            position={[
                              geo.geometry.coordinates[1],
                              geo.geometry.coordinates[0],
                            ]}
                          >
                            <Popup closeButton={false}>
                              <SpeciesPopup
                                value={{
                                  date: props.value.mapDate,
                                  item: item,
                                  onAddSpecies: () => {
                                    props.value.setGeoJsonGeometry(geo);
                                    props.value.setMappingId(item.id);
                                    props.value.setIsSpecies(true);
                                    props.value.setIsSideNavVisible(true);
                                  },
                                  onEditSpecies: (specie) => {
                                    props.value.setEditSpeciesData(specie);
                                    props.value.setMappingId(item.id);
                                    props.value.setIsSpecies(true);
                                    props.value.setIsSideNavVisible(true);
                                  },
                                  onDeleteSpecie: (specie) => {
                                    onDeleteSpecie(specie, item.id);
                                  },
                                  onColorChange: async (color) => {
                                    let result = await ApiClient(
                                      ApiClientType.patch,
                                      process.env.REACT_APP_LANDING_BASE_URL,
                                      "",
                                      {
                                        color: color,
                                        geometry: item.geometry,
                                        mapping_id: item.id,
                                      }
                                    );
                                    let c = await ApiClient(
                                      ApiClientType.get,
                                      process.env.REACT_APP_LANDING_BASE_URL,
                                      "",
                                      {
                                        date_time: props.value.mapDate,
                                      }
                                    );
                                    props.value.setSpeciesData(c);
                                  },
                                }}
                              />
                            </Popup>
                          </Marker>
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </FeatureGroup>
          </div>
        )}

        {speciesCoordinates?.features.map((location) => {
          return (
            <Marker
              eventHandlers={{
                click: (e) => {
                  if (!props.value.isWindy) {
                    setCenterCoords({ lat: 12.9716, lng: 77.5946 });
                    setZoomLevel(5);
                    localStorage.setItem("lat", 12.9716);
                    localStorage.setItem("lng", 77.5946);
                    localStorage.setItem("zoom", 5);
                    props.value.setGeoJsonGeometry(JSON.stringify(location));
                    props.value.setIsSpecies(true);
                    props.value.setIsSideNavVisible(true);
                  }
                },
              }}
              key={location.id}
              position={[
                location.geometry.coordinates
                  ? location.geometry.coordinates[1]
                  : location.geometry.y,
                location.geometry.coordinates
                  ? location.geometry.coordinates[0]
                  : location.geometry.x,
              ]}
            ></Marker>
          );
        })}
        {props.value.landingCenterCoordinates?.features.map((location) => (
          <Marker
            icon={
              `${location.properties.LANDINGNAM}-${location.properties.STATENAME}` ==
              props.value.searchLandingCenter.landing_center_name
                ? selectedLocationIcon
                : landingCenterIcon
            }
            eventHandlers={{
              click: (e) => {
                if (!props.value.isWindy) {
                  setCenterCoords({ lat: 12.9716, lng: 77.5946 });
                  setZoomLevel(5);
                  localStorage.setItem("lat", 12.9716);
                  localStorage.setItem("lng", 77.5946);
                  localStorage.setItem("zoom", 5);
                  getLandingCenterData(location.properties.system_id);
                }
              },
            }}
            key={location.id}
            position={[
              location.geometry.coordinates[1],
              location.geometry.coordinates[0],
            ]}
          >
            {!props.value.isWindy ? (
              <Popup>
                <LandingCenterPopup
                  value={{
                    onEdit: () => {
                      props.value.setIsSpecies(false);
                      props.value.setIsSideNavVisible(true);
                    },
                    landingCenter: props.value.selectedLandingCenter,
                  }}
                />
              </Popup>
            ) : null}
          </Marker>
        ))}
        <div className="leaflet-bottom leaflet-right">
          <CustomToast
            value={{
              showToast: showToast,
              setShowToast: setShowToast,
              toastBody: toastBody,
            }}
          />
        </div>
        <div className="leaflet-top leaflet-left">
          {props.value.isWindy ? null : <MouseCoordinates />}
        </div>
      </MapContainer>
    </div>
  );
}
