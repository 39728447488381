import GeoJson from "geojson";
import { GeoJsonType } from "../models/geoJsonType.js";
import { DrawType } from "../models/drawType.js";
import rewind from "geojson-rewind";
export function CoordinateConverter(coordinates) {
  let arr = [];
  coordinates.map((item) => {
    arr.push(item.reverse());
  });
  return arr;
}
