import NavbarComponent from "../components/navbar.js";
import Sidenav from "../components/sidenav.js";
import Map from "../components/map.js";
import { useState, useEffect } from "react";
import dateFormat from "dateformat";
import Windy from "../components/windy.js";

export default function HomePage({ user }) {
  const [isSideNavVisible, setIsSideNavVisible] = useState(false);
  const [isSpecies, setIsSpecies] = useState(true);
  const [mapDate, setMapDate] = useState(dateFormat(new Date(), "yyyy-mm-dd"));
  const [mapSearch, setMapSearch] = useState(null);
  const [landingCenterCoordinates, setLandingCenterCoordinates] = useState();
  const [geoJsonGeometry, setGeoJsonGeometry] = useState("");
  const [selectedLandingCenter, setSelectedLandingCenter] = useState();
  const [searchLandingCenter, setSearchLandingCenter] = useState({});
  const [mappingId, setMappingId] = useState("");
  const [editSpeciesData, setEditSpeciesData] = useState({});
  const [speciesData, setSpeciesData] = useState([]);
  const [isWindy, setIsWindy] = useState(false);

  return (
    <div style={{ height: "100vh" }}>
      <NavbarComponent
        value={{
          mapDate,
          setMapDate,
          searchLandingCenter,
          setSearchLandingCenter,
          landingCenterCoordinates,
          setLandingCenterCoordinates,
          setIsWindy,
          isWindy,
        }}
        user={user}
      />
      <Sidenav
        value={{
          setSpeciesData,
          isSideNavVisible,
          setIsSideNavVisible,
          isSpecies,
          landingCenterCoordinates,
          geoJsonGeometry,
          selectedLandingCenter,
          setMappingId,
          mappingId,
          setEditSpeciesData,
          editSpeciesData,
          mapDate,
        }}
      />
      <Map
        value={{
          searchLandingCenter,
          setSpeciesData,
          speciesData,
          mapDate,
          isSideNavVisible,
          setIsSideNavVisible,
          setIsSpecies,
          landingCenterCoordinates,
          setLandingCenterCoordinates,
          geoJsonGeometry,
          setGeoJsonGeometry,
          selectedLandingCenter,
          setSelectedLandingCenter,
          setMappingId,
          setEditSpeciesData,
          isWindy,
        }}
      />
    </div>
  );
}
