import GeoJson from "geojson";
import { GeoJsonType } from "../models/geoJsonType.js";
import { DrawType } from "../models/drawType.js";
import rewind from "geojson-rewind";
export function GeoJsonConverter(coordinates, type) {
  let data = {};
  let arr = [];
  let geoJsonType = {};
  if (type == DrawType.marker) {
    coordinates = coordinates._latlng;
    data["x"] = coordinates.lat;
    data["y"] = coordinates.lng;
    geoJsonType[GeoJsonType.point] = ["x", "y"];
  } else if (type == DrawType.polygon) {
    coordinates = coordinates._latlngs[0];
    coordinates.map((value) => {
      arr = [...arr, [value.lng, value.lat]];
    });
    arr.push(arr[0]);
    data[DrawType.polygon] = [arr];
    geoJsonType[GeoJsonType.polygon] = DrawType.polygon;
  }
  let result = GeoJson.parse(data, geoJsonType);
  return rewind(result, false);
}
