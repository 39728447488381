import FirstQuarterImg from "../assets/firstQuarterImg.js";
import WaxingCrescentImg from "../assets/waxingCrescentImg.js";
import NewMoonImg from "../assets/newMoonImg.js";
import WaningCrescentImg from "../assets/waningCrescentImg.js";
import LastQuarterImg from "../assets/lastQuarterImg.js";
import FullMoonImg from "../assets/fullMoonImg.js";
import WaxingGibbousImg from "../assets/waxingGibbousImg.js";
import WaningGibbousImg from "../assets/waningGibbousImg.js";

export const MoonPhases = [
  {
    id: "First Quarter",
    label: (
      <p>
        Half <br />
        Moon
      </p>
    ),
    image: (e) => <FirstQuarterImg value={{ isSelected: e }} />,
  },
  {
    id: "Waxing Crescent",
    label: (
      <p>
        4 days to
        <br />
        new moon
      </p>
    ),
    image: (e) => <WaxingCrescentImg value={{ isSelected: e }} />,
  },
  {
    id: "New Moon",
    label: (
      <p>
        New
        <br />
        Moon
      </p>
    ),
    image: (e) => <NewMoonImg value={{ isSelected: e }} />,
  },
  {
    id: "Waning Crescent",
    label: (
      <p>
        4 days after
        <br />
        new moon
      </p>
    ),
    image: (e) => <WaningCrescentImg value={{ isSelected: e }} />,
  },
  {
    id: "Last Quarter",
    label: (
      <p>
        8 days to
        <br />
        full moon
      </p>
    ),
    image: (e) => <LastQuarterImg value={{ isSelected: e }} />,
  },
  {
    id: "Waning Gibbous",
    label: (
      <p>
        4 days to
        <br />
        full moon
      </p>
    ),
    image: (e) => <WaningGibbousImg value={{ isSelected: e }} />,
  },
  {
    id: "Full Moon",
    label: (
      <p>
        Full
        <br />
        Moon
      </p>
    ),
    image: (e) => <FullMoonImg value={{ isSelected: e }} />,
  },
  {
    id: "Waxing Gibbous",
    label: (
      <p>
        4 days after
        <br />
        full moon
      </p>
    ),
    image: (e) => <WaxingGibbousImg value={{ isSelected: e }} />,
  },
];
