import { Offcanvas, Form, Row, Col, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import styles from "../styles/common/sidenav.module.css";
import LandingCenterForm from "./landingCenterForm.js";
import SpeciesForm from "./speciesForm.js";

export default function Sidenav(props) {
  return (
    <Offcanvas
      show={props.value.isSideNavVisible}
      scroll={false}
      backdrop={true}
      placement="end"
      onHide={() => {
        props.value.setIsSideNavVisible(false);
        props.value.setEditSpeciesData({});
        props.value.setMappingId("");
      }}
      id={styles.offcanvas}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {props.value.isSpecies && props.value.editSpeciesData == null ? (
            <>Add Details</>
          ) : (
            <>Edit Details</>
          )}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {props.value.isSpecies ? (
          <SpeciesForm
            value={{
              landingCenterCoordinates: props.value.landingCenterCoordinates,
              geoJsonGeometry: props.value.geoJsonGeometry,
              setMappingId: props.value.setMappingId,
              mappingId: props.value.mappingId,
              editSpeciesData: props.value.editSpeciesData,
              setSpeciesData: props.value.setSpeciesData,
              mapDate: props.value.mapDate,
              setIsSideNavVisible: props.value.setIsSideNavVisible,
            }}
          />
        ) : (
          <LandingCenterForm
            value={{
              selectedLandingCenter: props.value.selectedLandingCenter,
              setIsSideNavVisible: props.value.setIsSideNavVisible,
            }}
          />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}
