import "./App.css";
import HomePage from "./pages/homePage.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import * as reactRouterDom from "react-router-dom";
import { ApiClient } from "./helpers/apiClient.js";
import { ApiClientType } from "./models/apiClientType.js";

function App() {
  let session = useSessionContext();
  let navigate = useNavigate();
  const [userObj, setUserObj] = useState({});
  useEffect(() => {
    if (!session.doesSessionExist) {
      navigate("/auth");
    }
  }, []);
  const getUserObj = async () => {
    let result = await ApiClient(
      ApiClientType.get,
      process.env.REACT_APP_API_HOST,
      "/realm/sessioninfo/get-or-create",
      {}
    );
    if (result?.data) {
      result = await result.data;
      setUserObj({ ...result });
    }
  };
  if (
    session &&
    session.doesSessionExist &&
    userObj &&
    Object.keys(userObj).length === 0
  ) {
    getUserObj();
  }
  if (session.loading) {
    return <div className="loader"></div>;
  }
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <Routes>
        {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}
        <Route path="/" element={<Navigate to="/home" />} />
        <Route
          path="/home"
          element={
            <SessionAuth
              onSessionExpired={() => {
                navigate("/auth");
              }}
            >
              <HomePage user={userObj} />
            </SessionAuth>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
