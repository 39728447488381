import { useMap, useMapEvents } from "react-leaflet";
export default function ChangeView({
  setZoomLevel,
  setCenterCoords,
  isWindy,
  center,
  zoom,
  landingCenter,
}) {
  const map = useMap();
  if (isWindy) {
    map.zoomControl.disable();
    map.dragging.disable();
    map.scrollWheelZoom.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.boxZoom.disable();
    map.flyTo(center, zoom);
  } else {
    map.zoomControl.enable();
    map.dragging.enable();
    map.scrollWheelZoom.enable();
    map.touchZoom.enable();
    map.doubleClickZoom.enable();
    map.boxZoom.enable();
    if (localStorage.getItem("isWindyDone") == "true") {
      map.flyTo(center, 5);
      setTimeout(() => {
        localStorage.setItem("isWindyDone", false);
        map.flyTo(center, 10);
      }, 500);
    } else {
      map.flyTo(center, zoom);
    }
  }
  return null;
}
