import styles from "../styles/common/popup.module.css";
import { Form } from "react-bootstrap";
import { ApiClient } from "../helpers/apiClient.js";
import { ApiClientType } from "../models/apiClientType.js";
import { GeoJsonType } from "../models/geoJsonType.js";

export default function SpeciesPopup(props) {
  let geo = JSON.parse(props.value.item.geometry);
  return (
    <div id={styles.popup}>
      <div id={styles.heading}>
        <p>
          {props.value.date}
          <span
            onClick={() => {
              props.value.onAddSpecies();
            }}
            style={{
              color: "#0A82C6",
              cursor: "pointer",
              float: "right",
              fontWeight: "normal",
            }}
          >
            Add Species
          </span>
        </p>
        {geo.geometry.type != GeoJsonType.point ? (
          <Form style={{ marginLeft: "10px" }}>
            <Form.Control
              type="color"
              onChange={(e) => props.value.onColorChange(e.target.value)}
              id="exampleColorInput"
              defaultValue="#000000"
              value={
                props.value.item.color ? props.value.item.color : "#000000"
              }
              title="Choose your color"
            />
          </Form>
        ) : null}
      </div>
      <div id={styles.body}>
        {props.value.item.species.map((item, index) => {
          return (
            <div key={item.id}>
              <span style={{ fontSize: "15px" }}>
                {item?.species_name.split(",")[0]}
              </span>
              <span style={{ color: "grey" }}>
                {item?.species_name.split(",")[1]}
              </span>
              <div
                style={{
                  marginTop: "5px",
                  marginBottom: "10px",
                  color: "grey",
                }}
              >
                {item?.catch_quantity} {item?.catch_quantity_unit} |
                {item?.landing_center?.length != 0 && item?.landing_center ? (
                  <>
                    {item?.landing_center[0]?.landing_center_name.slice(0, 20)}
                    ...
                  </>
                ) : null}
                {item?.landing_center?.length > 1
                  ? `+ ${item?.landing_center?.length - 1}`
                  : null}
              </div>
              <div>
                <span
                  onClick={() => props.value.onDeleteSpecie(item)}
                  style={{ color: "#D73526", cursor: "pointer" }}
                >
                  Delete
                </span>
                <span
                  onClick={() => {
                    props.value.onEditSpecies(item);
                  }}
                  style={{
                    color: "#0A82C6",
                    cursor: "pointer",
                    float: "right",
                  }}
                >
                  Edit/View Details
                </span>
              </div>
              {index == props.value.item.species.length - 1 ? (
                <div style={{ marginBottom: "10px" }}></div>
              ) : (
                <hr style={{ marginTop: "20px" }} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
