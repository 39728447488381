// import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { BrowserRouter as Router } from "react-router-dom";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Session from "supertokens-auth-react/recipe/session";
import ThirdPartyPasswordless, { thirdPartySignInAndUp } from "supertokens-auth-react/recipe/thirdpartypasswordless";

Session.addAxiosInterceptors(axios);

SuperTokens.init({
  appInfo: {
    appName: "pfz-webapp",
    apiDomain: process.env.REACT_APP_API_HOST,
    websiteDomain: "https://www.pfzlogs.zurf.tech",
    apiBasePath: "/auth",
    apiGatewayPath: "/realm",
  },
  recipeList: [
    ThirdPartyPasswordless.init({
      emailVerificationFeature: {
        mode: "REQUIRED",
      },

      signInUpFeature: {
        providers: [ThirdPartyPasswordless.Google.init()],
        defaultCountry: "IN",
      },
      contactMethod: "PHONE",
      getRedirectionURL: async (context) => {
        if (context.action === "SUCCESS") {
          if (context.redirectToPath !== undefined) {
            // we are navigating back to where the user was before they authenticated
            return context.redirectToPath;
          }
          return "/home";    
        }
        return undefined;
      },
    }),

    Session.init({
      sessionScope: ".zurf.tech",
    }),
  ],
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
		<SuperTokensWrapper>
			<Router>
				<App />
			</Router>
		</SuperTokensWrapper>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
