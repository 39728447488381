import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import React, { useState } from "react";

export default function CustomToast(props) {
  return (
    <Row>
      <Col>
        <Toast
          onClose={() => props.value.setShowToast(false)}
          show={props.value.showToast}
          delay={5000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Message:</strong>
          </Toast.Header>
          <Toast.Body>{props.value.toastBody}</Toast.Body>
        </Toast>
      </Col>
    </Row>
  );
}
