export default function NewMoonImg(props) {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="27"
        cy="27"
        r="26.5"
        fill="#F2F2F2"
        stroke={props.value.isSelected ? "#0a82c6" : "#E2E2E2"}
      />
      <path
        d="M42.3067 16.316C41.156 18.1087 41.232 16.6447 39.4393 15.4939C37.6466 14.3432 34.6641 13.9415 35.8148 12.1488C36.9655 10.3561 37.1284 9.5969 38.9211 10.7476C40.7138 11.8984 43.4575 14.5233 42.3067 16.316Z"
        fill={props.value.isSelected ? "#0a82c6" : "#E6E6E6"}
      />
      <path
        d="M40.5714 18.2857C40.5714 18.9958 39.9958 19.5714 39.2857 19.5714C38.5756 19.5714 38 18.9958 38 18.2857C38 17.5756 39.5756 17 40.2857 17C40.9958 17 40.5714 17.5756 40.5714 18.2857Z"
        fill={props.value.isSelected ? "#0a82c6" : "#E6E6E6"}
      />
      <path
        d="M43.4013 17.0643C44.1107 17.0947 44.6611 17.6945 44.6306 18.404C44.6001 19.1134 44.0003 19.6638 43.2909 19.6333C42.5815 19.6028 42.074 18.0039 42.1045 17.2945C42.1349 16.5851 42.6918 17.0338 43.4013 17.0643Z"
        fill={props.value.isSelected ? "#0a82c6" : "#E6E6E6"}
      />
      <path
        d="M36.5714 15.4115C36.5714 16.1216 34.9958 15.6972 34.2857 15.6972C33.5756 15.6972 33 15.1216 33 14.4115C33 13.7014 34.5756 14.1258 35.2857 14.1258C35.9958 14.1258 36.5714 14.7014 36.5714 15.4115Z"
        fill={props.value.isSelected ? "#0a82c6" : "#E6E6E6"}
      />
    </svg>
  );
}
