import { useMap, useMapEvents } from "react-leaflet";
export default function ChangeView({
  setZoomLevel,
  setCenterCoords,
  isWindy,
  center,
  zoom,
}) {
  const map = useMap();
  useMapEvents({
    dragend: (e) => {
      // setCenterCoords({
      //   lat: map.getCenter().lat,
      //   lng: map.getCenter().lng,
      // });
      localStorage.setItem("lat", map.getCenter().lat);
      localStorage.setItem("lng", map.getCenter().lng);
    },
    zoomend: (e) => {
      // setZoomLevel(parseInt(map.getZoom()));
      localStorage.setItem("zoom", parseInt(map.getZoom()));
    },
  });
  return null;
}
